import React, { useMemo } from "react";  

import {  
    DashboardTitle,
    StudentImage,
    StudentRate,
    StudentRow,
    StudentStats
} from "./styled";

import { parseStrapiImage } from "utils";
import moment from "moment/moment";
import { MobileHide } from "ui/styled";

export default function CardStudentID({ student }){    
    return ( 
        <> 
            <DashboardTitle big>Aluno { student?.name }</DashboardTitle>
            <StudentRow>
                <StudentImage src={ student?.avatar?.url ? parseStrapiImage(student?.avatar?.url) : "/images/student.png"} />
                <StudentStats>
                    Média
                    <StudentRate>{ student?.media || "0"}</StudentRate>
                </StudentStats>
                <StudentStats>
                    Performance
                    <StudentRate>{ student?.classification || "F" }</StudentRate>
                </StudentStats>
            </StudentRow>
            <StudentStats>Último acesso: { moment(student?.lastaccess)?.format("LLL") } </StudentStats>
            <DashboardTitle big>
                <MobileHide>
                        #{ student?.accessCode }
                </MobileHide>
            </DashboardTitle>         
        </>
    );
}