import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    
    font-size: 22px;
    font-weight: bold; 
    margin-bottom: 24px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
    ${
        props => props.big ? `
            font-size: 32px;
        ` : ``
    }

    @media(max-width: 991px){
        font-size: 18px;
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;




export const CardHome = styled.div.attrs({ 
})`
    min-height: 110px;
    padding: 24px 12px 12px;
    border: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };
    margin: 0 0 24px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;

export const CardHomeTitle = styled.div.attrs({ 
})`
    font-size: 22px;
`;













export const StudentImage = styled.img.attrs({ 
    width: "100%"
})`
    max-width: 180px;
    aspect-ratio: 1 / 1;
    border-radius: 90px;
    object-fit: cover;
    margin-bottom: 18px;

    @media(max-width: 991px){
        margin: 0 auto 18px;
    }
`;

export const StudentRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 48px;
`;

export const StudentStats = styled.div.attrs({ 
})`
    font-size: 18px;

    @media(max-width: 991px){
        font-size: 14px;
    }
`;

export const RowTheme = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
`;

export const StudentRate = styled.div.attrs({ 
})`
    font-size: 78px;
    font-weight: 700;
    @media(max-width: 991px){
        font-size: 50px;
    }
`;


export const DashboardOptionsRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 0 0 32px;
`;


export const PlayIcon = styled.img.attrs({ 
    src:'/icons/play.svg'
})`
    width: 32px;
`;

export const PaintIcon = styled.img.attrs({ 
    src:'/icons/paint.svg'
})`
    width: 28px;
`;



export const ContentRowInfos = styled.div.attrs({ 
})`
    display: flex;
    padding: 18px 0;
`;

export const ContentRowInfosItem = styled.div.attrs({ 
})`
    flex:1;
    font-size: 24px;
    font-weight: 700;
`;



export const ContentStudentClasses = styled.div.attrs({ 
})`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
`;


export const WrapperThemes = styled.div.attrs({ 
})`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    max-width: 600px;
`;

export const ThemeColor = styled.div.attrs({ 
})`
    width: 96px;
    height: 96px;
    border-radius: 48px;
    background: ${ p => p.color};

    ${
        p => p.small ? `
            width: 32px;
            height: 32px;
            border-radius: 16px;
        ` : `
            cursor:pointer;
            transition: all .3s ease;
            &:hover{
                transform: scale(1.1);
            }
        `
    }

`;
