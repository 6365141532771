import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 12px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }

    @media(max-width: 991px){
        font-size: 18px;
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;




export const CardHome = styled.div.attrs({ 
})`
    min-height: 110px;
    border: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };
    margin: 0 0 24px;

    background: url(${ p => p.image }) no-repeat bottom+70px center / cover;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;

    box-shadow: 1px 3px 6px ${ props => props.theme.palette.colors.lightshadow };

    aspect-ratio: 9 / 9  ;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;




export const CardHomeSquare = styled.div.attrs({ 
})`
    background:  ${ props => props.theme.palette.colors.backgrounddark };
    padding: 12px 12px 12px ;
    border-bottom-left-radius: 16px;
`;


export const CardHomeTitle = styled.div.attrs({ 
})`
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 6px;
    color:  ${ props => props.theme.palette.colors.white };
`;

export const CardHomeText = styled.div.attrs({ 
})`
    font-size: 16px;
    font-weight: 400;
    color:  ${ props => props.theme.palette.colors.white };
`;