import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'


export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold; 
    margin-bottom: 24px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }

    @media(max-width: 991px){
        font-size: 18px;

        ${
            props => props.big ? `
                font-size: 24px;
            ` : ``
        }
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;


export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;




export const StudentImage = styled.img.attrs({ 
    width: "100%"
})`
    max-width: 180px;
    border-radius: 90px;
    object-fit: cover;
    margin-bottom: 18px;
    @media(max-width:991px){
        margin: 0 auto 0px;
    }
`;

export const StudentRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 48px;
`;

export const StudentStats = styled.div.attrs({ 
})`
    font-size: 18px;
`;

export const StudentRate = styled.div.attrs({ 
})`
    font-size: 78px;
    font-weight: 700;
`;

export const DashboardOptionsRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 0 0 32px;
`;