import React, { useMemo } from "react";  

import {  
    ButtonQr,
    CardFormActions,
    CardFormButton,
    CardHome,
    CardHomeTitle,
    CardTextRight,
    CheckedImage,
    ContentQr,
    ContentQrLimit,
    ContentRowInfos,
    ContentRowInfosItem,
    ContentStudentClass,
    ContentStudentClassContent,
    ContentStudentClassImage,
    ContentStudentClassText,
    ContentStudentClasses,
    DashboardOptionsRow,
    DashboardText,
    DashboardTitle,
    StudentImage,
    StudentRate,
    StudentRow,
    StudentStats
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Input from "components/Form/Input";
import useController from "./controller";
import CustomButton from "components/Form/CustomButton";
import { CoinImage, DasboardSpacer, MobileHide } from "ui/styled";
import { coinize } from "utils/parsers";
import BodyFeedback from "components/Mobile/Feedback";
import { API_ENDPOINT } from "services/api";

import QRCode from "react-qr-code";
import { numerize, parseStrapiImage } from "utils";
import moment from "moment/moment";
import CardClass from "components/Cards/CardClass";
import CardStudentID from "components/Cards/CardStudentID";
import DailyChart from "components/Dashboard/Charts/Daily";

export default function DashboardCreatorStudents(){   

    const { 
        changeForm,
        formValue,
        loading,
        saving,
        saveForm,
        editing,
        showing, setShowing,
        getting, studentAccess,
        student, openModalLessonResponse,
        navigate,
        handleRemove
    } = useController() 


  const mobileUrl = useMemo(() => {
    return `${API_ENDPOINT}/deeplink/login/${editing?.accessCode}`
  }, [editing])
  
  const webUrl = useMemo(() => {
    return `${window.location.origin}/student/lessons/${editing?.accessCode}`
  }, [editing])

    return ( 
        <>
            <ContainerAuthenticated> 

                <Row>
                    { !editing ? <Col sm={12} md={3} /> : null }
                    <Col sm={12} md={6}> 
                        {
                            !editing ? <DashboardTitle>Criar aluno</DashboardTitle> : <>
                                <CardStudentID student={editing} />
                                <DashboardOptionsRow>
                                    { 
                                        showing ? null : <>
                                            <CustomButton onClick={() => setShowing(true)}>Editar</CustomButton>
                                            <CustomButton onClick={() => window.open(webUrl, "royal-game")}>Acessar painel deste aluno</CustomButton>
                                        </>
                                    }
                                    { 
                                        !showing ? null : <>
                                            <CustomButton onClick={handleRemove}>Remover Aluno</CustomButton>
                                        </> 
                                    }
                                </DashboardOptionsRow>
                            </>
                        }


                        {
                            editing && !showing ? <>
                                <MobileHide>
                                    <DailyChart />
                                </MobileHide>
                            </> : <>
                                <Input placeholder="Nome do aluno" value={formValue("name")} onChange={e => changeForm(e.target.value, "name")} />
                                {
                                    !editing ? null : <>
                                        <DasboardSpacer />
                                        <Input type="textarea" placeholder="Descrição (Essa informação é privada, não aparece para os alunos)" value={formValue("description")} onChange={e => changeForm(e.target.value, "description")} />
                                    </>
                                }
                                <CardFormActions>
                                    <CardFormButton>
                                        <CustomButton loading={saving} onClick={saveForm}>Salvar</CustomButton>
                                    </CardFormButton>
                                </CardFormActions>
                            </>
                        }

                    </Col>
                    {
                        !editing ? null : 
                            <Col sm={12} md={6}> 
                                
                                <ContentQr>
                                    <div>
                                        <DashboardText>O <b>Código</b> e o <b>QR abaixo</b> são a identificação do seu aluno na RoyalAcademy, e são utilizados para <b>entrar no aplicativo</b> do aluno</DashboardText>   
                                        <DashboardTitle />
                                        <DashboardTitle big>#{ editing?.accessCode }</DashboardTitle>   
                                        <CustomButton loading={getting} onClick={studentAccess}>Enviar QrCode de Acesso</CustomButton>
                                    </div>
                                    <ContentQrLimit>
                                        <QRCode
                                            size={1024}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={ mobileUrl }
                                            viewBox={`0 0 1024 1024`}
                                        />
                                    </ContentQrLimit>
                                </ContentQr> 
                                
                                {
                                    !student || !student?.classes?.length ? <>
                                        <CustomButton outline onClick={() => navigate("dashboard/contentor/classes")} >Adicione o aluno a uma turma!</CustomButton> 
                                    </> :  <>
                                        <ContentRowInfos>
                                            <ContentRowInfosItem>Turmas ({student?.classes?.length})</ContentRowInfosItem> 
                                        </ContentRowInfos>
                                        <ContentStudentClasses>
                                            {
                                                student?.classes?.map((m, k)=> 
                                                    <CardClass key={k} item={m} rated />
                                                )
                                            } 
                                        </ContentStudentClasses>
                                    </>
                                }

                            </Col>
                    }
                </Row>
    
            </ContainerAuthenticated> 
        </>
    );
}