import styled from 'styled-components'  


export const FormSteperButton = styled.div.attrs({ 
})`
    padding: 12px;
    background: ${ p => p.theme.palette.colors?.[ p?.color ? p.color : 'backgrounddark' ] }; 
    color: ${ p => p.theme.palette.colors.white }; 

    display: flex;
    flex-direction: column;
    align-items: center;
    jsutify-content: center;

    text-align: center;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;

    ${
        p=>p.small ? `
            padding: 8px;
            font-size: 12px;
            line-height: 22px;
            font-weight: 400;
        ` : ``
    }



    ${
        p => p.outline ? `
            background: transparent;
            border: 1px solid ${ p.theme.palette.colors?.[ p?.color ? p.color : 'backgrounddark' ] };
            color: ${ p.theme.palette.colors?.[ p?.color ? p.color : 'backgrounddark' ] };
        ` : ``
    }
    
    @media(max-width: 991px){
        padding: 6px;
    }

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;

export const FormSteperButtonContent = styled.div.attrs({ 
})`
    margin: auto;
`;