import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'


export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 24px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }

    @media(max-width: 991px){
        font-size: 18px;
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;




export const CardHome = styled.div.attrs({ 
})`
    min-height: 110px;
    padding: 24px 12px 12px;
    border: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };
    margin: 0 0 24px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;

export const CardHomeTitle = styled.div.attrs({ 
})`
    font-size: 22px;
`;

export const ContentRight = styled.div.attrs({ 
})`
    display: flex; 
    flex-wrap: wrap;
    overflow: hidden;
    gap: 16px 0;
`;

export const ContentRightItem = styled.div.attrs({ 
})`
    padding: 12px;
    font-size: 18px;
    background:  ${ props => props.active ? props.theme.palette.colors.backgrounddark : 'transparent' };
    color:  ${ props => props.active ? props.theme.palette.colors.white : props.theme.palette.colors.black };
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;



export const ContentRightTitle = styled.div.attrs({ 
})`            
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    color: ${ p => p.theme.palette.colors.black };
`;














export const DashboardSideMascot = styled.div.attrs({ 
})`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 100px;
    background: url(/images/pets/white.png) no-repeat center bottom / contain;
    cursor: pointer;
    transition: all .3s ease;
    
    ${
        p => p.generated ? `
            div {
                display: none;
            }
            &:hover div{
                display: block;
            }
        ` : ``
    }
    
    & :hover {
        transform: scale(1.05);
    }
`;


export const DashboardSideMascotBaloon = styled.div.attrs({ 
})`
    position: absolute;
    right: 100px;
    top: -40px; 
    border-top-left-radius: 20px; 
    border-top-right-radius: 20px; 
    border-bottom-left-radius: 20px; 
    padding: 12px;
    background: ${ props => props.theme.palette.colors.white };
    border: 1px solid ${ props => props.theme.palette.colors.backgrounddark };
    text-align: center;
`;




export const SimpleRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;



export const CardOptionsWrapIcon = styled.div.attrs({ 
})`
    width: 80px;
    height: 80px;
    border-radius: 60px;

    background:  ${ props => props.theme.palette.colors.backgrounddark } url(/icons/lens.svg) no-repeat center center / contain ;
    margin: 0 auto 48px;

    transition: all .3s ease;

    ${
        p => p.loading ? `
            animation: pulse infinite .7s linear; 
        
            @keyframes pulse {
                0% {
                    transform: scale(1);
                }
                
                50% { 
                    transform: scale(1.3);
                }
                
                100% {
                    transform: scale(1);
                }
            }
        ` : ``
    }

`;

export const CardOptionsWrap = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`;

export const CardOption = styled.div.attrs({ 
})`
    position: relative;
    border: 1px solid ${ props => props.theme.palette.colors.backgrounddark };
    padding: 24px;
    width: 100%;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    
    box-shadow: 0px 3px 6px ${ props => props.theme.palette.colors.lightshadow };

    cursor: pointer;
    transition: all .3s ease;
    &:hover {
        transform: scale(1.0);
    }

    & .edit-button{
        display: none;
    }

    &:hover .edit-button{
        display: block;
    }
`;

export const CardOptionTitle = styled.div.attrs({ 
})`
    font-size: 22px;
    color: ${ props => props.theme.palette.colors.backgrounddark };
    margin-bottom: 6px;
    padding-right: 40px;
`;

export const CardOptionResponse = styled.div.attrs({ 
})`
    font-size: 16px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        p => p.bold ? `
            padding: 12px 0;
            font-weight: 700;
        ` : ``
    }
    ${
        p => p.responsed ? `
            &:before{
                content: " • ";
                color: ${ p.theme.palette.colors.backgrounddark };
            }
        ` : ``
    }
    ${
        p => p.correct ? `
            color: ${ p.theme.palette.success.main };
            font-weight: 900;
        ` : ``
    }
`;




export const CardForm = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    gap: 24px;
    padding: 48px 0;
`;

export const CardFormButton = styled.div.attrs({ 
})`
`;

export const CardOptionDecoration = styled.div.attrs({ 
})`
    position: absolute;
    width: 50px;
    height: 50px;
    background: ${ props => props.theme.palette.colors.backgrounddark };
    bottom: 0;
    right: 0;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CardOptionDecorationIcon = styled.img.attrs({ 
    src:"/icons/verified.svg",
    width: 32
})`
    margin: -0px -2px 0 0;
`;




export const CardFormBetweeRow = styled.div.attrs({ 
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;









export const CardFormResponse = styled.div.attrs({ 
})`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    border: .5px solid ${ props => props.theme.palette.colors.backgrounddark };

    border-radius: 16px 16px 16px 0;

    padding: 24px;
    gap: 24px;

    @media(max-width: 991px){
        flex-direction: column;
    }
`;

export const CardFormResponseFlex = styled.div.attrs({ 
})`
    width: 100%;
`;

export const CardFormResponseActions = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;

    @media(max-width: 991px){
        flex-direction: column-reverse;
    }
`;


export const IconRemove = styled.img.attrs({ 
    src: "/icons/trash.svg"
})`
`;
