import React, { useState } from "react";  

import { useHistory } from 'react-router-dom';

import {  

    DashboardMenu,
    DashboardMenuHeader,
    DashboardMenuContainer,
    DashboardMenuHeaderIcon,

    DashboardMenuHeaderUserContent,
    DashboardMenuHeaderUserImage, 

    DashboardMenuOption,
    DashboardMenuContent,
    DashboardMenuFooter,
    
    DashboardVersionContent,
    DashboardVersionText,
    DashboardMenuSpacer,
    DashboardMenuUniLogo,
    DashboardMenuDecoration,
    DashboardMenuHeaderUserImageContainer,
    DashboardMenuHeaderUserImageContainerFade,
    HeaderMenu,
    MenuIcon,
    DashboardMenuOverlay

} from "./styled"; 

import Button from "components/Form/Button";
import { DoLogout } from "services/authentication";
import { Touch } from "ui/styled";

const packageJson = require('../../../../package.json')

export default function DashboardSide({ opened, setOpened }){ 
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const [mopen, setMopen] = useState(false)

    const verifyClose = e => {
        if(!e.target.closest('.menu-contant')){
            setOpened(false)
        }
    }

    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    const sideOptions = [
        // { title:"Dashboard", action: () => navigate('dashboard') },
        
        // { title:"Criador de", action: () => navigate('dashboard/creator') }, 
        // { submenu:true, title:"Tarefas", action: () => navigate('dashboard/creator/tasks') }, 
        // { submenu:true, title:"Alunos", action: () => navigate('dashboard/creator/students') }, 
        // { submenu:true, title:"Aulas", action: () => navigate('dashboard/creator/lessons') }, 
        // { submenu:true, title:"Turmas", action: () => navigate('dashboard/creator/classes') }, 
        
        
        // { title:"Acervo de", action: () => navigate('dashboard/contentor') },
        { submenu:true, title:"Ínicio", action: () => {navigate('dashboard'); setMopen(false)} }, 
        { submenu:true, title:"Questões", action: () => {navigate('dashboard/contentor/tasks'); setMopen(false)} }, 
        { submenu:true, title:"Exercícios", action: () => {navigate('dashboard/contentor/lessons'); setMopen(false)} }, 
        { submenu:true, title:"Alunos", action: () => {navigate('dashboard/contentor/students'); setMopen(false)} }, 
        { submenu:true, title:"Turmas", action: () => {navigate('dashboard/contentor/classes'); setMopen(false)} }, 

    ]

    const sideSuboptions = [
        { title:"Termos de uso", action: () => navigate('terms') }, 
        { title:"Politica de privacidade", action: () => navigate('privacity') },
    ]

    return ( 
        <>   

        <HeaderMenu onClick={() => setMopen(!mopen)}>
            <MenuIcon />
        </HeaderMenu>
        
            {/* <DashboardMenuContainer onClick={verifyClose}> */}
                <DashboardMenu open={mopen}>
                    {/* <DashboardMenuHeader onClick={() => setOpened(false)}>
                        <DashboardMenuHeaderIcon src={'/icons/close-white.svg'} />
                        fechar
                    </DashboardMenuHeader>  */}
                    {/* <DashboardMenuHeaderUserContent>
                    </DashboardMenuHeaderUserContent>  */}
                    
                    <DashboardMenuHeaderUserImageContainer>
                        <DashboardMenuHeaderUserImageContainerFade>
                            <DashboardMenuHeaderUserImage onClick={() => window.location.reload()} /> 
                        </DashboardMenuHeaderUserImageContainerFade>
                    </DashboardMenuHeaderUserImageContainer>

                    {
                        sideOptions?.map((item, key) =>
                            <DashboardMenuContent key={key}>
                                <DashboardMenuOption submenu={item?.submenu} onClick={item?.action}> 
                                    { item?.title }
                                </DashboardMenuOption>
                            </DashboardMenuContent>
                        )
                    }

                    <DashboardMenuSpacer />

                    {
                        sideSuboptions?.map((item, key) =>
                            <DashboardMenuContent key={key}>
                                <DashboardMenuOption onClick={item?.action}> 
                                    { item?.title }
                                </DashboardMenuOption>
                            </DashboardMenuContent>
                        )
                    } 
                    
                    <DashboardMenuFooter>
                        <Touch onClick={() => window.open("https://unicorntechhouse.com", "uni")}>
                            <DashboardMenuUniLogo />
                        </Touch>
                        {/* <Button primary outline onClick={exit} centred>
                            Sair
                        </Button> */}
                        
                        <DashboardVersionContent>
                            <DashboardVersionText>{ packageJson?.version }</DashboardVersionText>
                            <DashboardVersionText>{ packageJson?.revision }</DashboardVersionText>
                        </DashboardVersionContent> 
                       
                    </DashboardMenuFooter>
                    {/* <DashboardMenuDecoration /> */}
                </DashboardMenu>
                { !mopen ? null : <DashboardMenuOverlay onClick={() => setMopen(!mopen)} /> }
            {/* </DashboardMenuContainer>  */}
        </>
    );
}