import styled from 'styled-components' 
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';

export let WindowScreen = {
    width: 480,
    height: 780,
    landscape: false
}

export const hexToRgb = (hex) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return [(c>>16)&255, (c>>8)&255, c&255].join(',') ;
    }
    return `255,255,255`
}  

export const Touch = styled.div.attrs({ 
})`         
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;

// export const Load = styled(ReactLoading).attrs( p => ({ 
export const Load = styled.div.attrs( p => ({ 
    type:'spin',
    color: p.colored ? p.theme.palette.colors.backgrounddark : p.theme.palette.colors.white,
    height:20,
    width:20
}))`          
`;
 
export const Animation = styled(Lottie).attrs( props => ({  
    options:{
        loop: true,
        autoplay: true, 
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        animationData: props.animationData
    }, 
    width: props.width ? props.width : 404
}))`        
    max-width: 100%;
`;  

export const EmptyMessage = styled.div.attrs({ 
})`         
    padding: 32px; 
    text-align: center;
    font-size: 14px;
    color: ${ props => props.theme.palette.colors.black };
`;

export const LoadCenter = styled.div.attrs({ 
})`         
    width: 20px;
    margin: 32px auto; 
    display: flex;
    justify-content: center;
`;


export const AnimationCenter = styled.div.attrs({ 
})`         
    width: 100%;
    width: 280px;
    margin: 32px auto;
    aspect-ratio: 1 / 1; 
`;

export const LoadingCookies = styled(AnimationCenter).attrs({ 
    children: <Animation height={280} width="100%" animationData={require("assets/lotties/app/loading.json")} />
})`
`;


export const DecoratedScroll = styled.div.attrs({ 
})`

    overflow: auto; 

    ::-webkit-scrollbar-thumb {
        background-color: ${ props => props.theme.palette.secondary.main };
        outline: 0 solid ${ props => props.theme.palette.secondary.main };
    }

    ::-webkit-scrollbar {
        width: 1px;
        height: .2rem;
    }

    ::-webkit-scrollbar-track {  
    }
`;


export const FormContainer = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
`;

export const FormAction = styled.div.attrs({ 
})`
    max-width: 200px;
    width: 100%;
    ${
        p => p.big ? `
            max-width: 380px;
        ` : ``
    }
`;

export const GeneratedAction = styled.div.attrs({ 
})`
    max-width: 70px;
    background: white;
    width: 100%;
    margin-top: 24px;
    position: absolute;
    top: -12px;
    right: 24px;
`;

export const ManualAction = styled.div.attrs({ 
})`
    max-width: 280px;
    width: 100%;
    margin: 0 auto 24px;
`;

export const FormActions = styled.div.attrs({ 
})`
    display: flex;
    justify-content: flex-end;
    margin: 24px 0 48px;
    gap: 24px;
`;

export const BetweenRow = styled.div.attrs({ 
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

export const MaxButton = styled.div.attrs({ 
})`
    max-width: 220px;
    width: 100%;
    ${
        p=>p.small? `
            max-width: 70px;
        ` : ``
    }
    ${
        p=>p.white? `
            background: ${ p.theme.palette.colors.white };
        ` : ``
    }
    @media(max-width: 991px){
        max-width: 160px;
        ${
            p=>p.small? `
                max-width: 60px;
            ` : ``
        }
    }
`;

export const DasboardSpacer = styled.div.attrs({ 
})`
    margin-bottom: 24px;
`;


export const FormWrapper = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`;





export const Question = styled.div.attrs({
    typing:1,
    minDelay:10,
    maxDelay:50,
})`
    font-size: 18px;
    color: ${ p => p.theme.palette.colors.black };
    margin-bottom: 20px;
`; 

export const QuestionImage = styled.div.attrs({
    resizeMode: 'contain'
})`
    margin-top: 10px;
    width: 100%;
    height: 40lvh;
    border-radius: 5px;
    overflow: hidden; 
    background: url(${ p => p.source?.uri }) no-repeat center center / contain;

`;
















export const Overlay = styled.div.attrs({})` 
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    background: ${props => props.theme.palette.colors.shadow}; z-index: 1001; cursor: auto;
`;
    
export const ModalContainer = styled.div.attrs({})` 
    position: fixed; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    padding: 20px; 
    width: 100%; 
    box-sizing: border-box;

`;

export const ModalContentParent = styled.div.attrs({})` 
    width: 100%;
    max-width: 640px; 
    ${
        p => p.big ? `
            max-width: 1280px; 
            width: 100%;
        ` : ``  
    }
`;

export const ModalContent = styled.div.attrs({})` 
    gap: 16px; 
    width: 100%; 
    overflow-y: auto; 
    max-height: 90vh; 
    box-sizing: border-box; 
    animation: slideDown 0.3s ease-out; 
    display: flex; 
    flex-direction: column; 
    gap: 16px; 
    max-width: 640px; 
    ${
        p => p.big ? `
            max-width: 1280px; 
            width: 100%;
        ` : ``  
    }
    background: ${props => props.theme.palette.colors.white}; 
    padding: 24px 40px 40px 40px; 
    border-radius: 8px; 


    ${
        p => p.mobile ? `
            
        ` : ``  
    }
    
    @keyframes slideDown { 
        from { 
            opacity: 0; 
            transform: translateY(-100%); 
        } to { 
            opacity: 1; 
            transform: translateY(0); 
        } 
    } @keyframes 
    
    slideUp { 
        from { 
            opacity: 1; 
            transform: translateY(0); 
        } to { 
            opacity: 0; 
            transform: translateY(-100%); 
        } 
    } 
    
    @media (max-width: 1000px) { 
        padding: 20px; 
        max-width: 100%; 
        min-width: 100%; 
    } 
    
    @media (max-width: 480px) { 
        padding: 15px; 
        gap: 15px; 
    }

 
`;

export const FormText = styled.div.attrs({})` 
    font-size: 18px; 
    font-weight: 300; 
    text-align: left; 
    color: ${props => props.theme.palette.colors.black}; 
    margin-bottom: 8px;
`;

export const Icon = styled.img.attrs((props) => ({ 
    src: `/icons/${props.icon}.svg`
}))` 
    margin: ${props => props.nomargin ? `0` : `0 2px`} ; 
    z-index: 1; 
    ${props => props.pointer ? `cursor: pointer;` : ``}
`;

export const Title = styled.h1.attrs({})` 
    
    font-size: ${props => props.small ? `20px` : `48px`}; 
    font-weight: 900; 
    text-align: ${props => props.centred ? `center` : `left`}; 
    margin-top: ${props => props.nomargin ? `0` : `32px`}; 
    margin-bottom: ${props => props.nomargin ? `0` : `32px`}; 
    text-transform: ${props => props.upper ? `uppercase` : `none`}; 
    color: ${props => props.theme.palette.colors.black };
`;

export const CoinImage = styled.img.attrs(p => ({
    alt:"coin",
    src:"/images/gifs/coin.gif",
    width: p?.huge ? 100 : p?.small ? 18 : 36
}))` 
    ${
        p => p.negative ? `
            margin: -6px 0 0 -6px;
        ` : ``
    }
`;

export const SimpleRow = styled.div.attrs({
})` 
    display: flex;
    flex:1;
`;
export const Row = styled.div.attrs({
})` 
    display: flex;
    gap: 12px;
    ${
        props => props.center ? `justify-content: center;` : ``
    }
`;



export const WrapContainer = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;  
    margin-horizontal: -30px; 
    padding-horizontal: ${ WindowScreen.width * .052 }px;
`;


export const MemoryImageFeedback = styled.div.attrs({
    resizeMode: 'cover'
})`
    background: url(${ p => p.source?.uri }) no-repeat center center / cover;
    margin-top: 20px;
    width: ${ props => WindowScreen.width * ( props.small ? .15 : .2 ) }px;
    height: ${ props => WindowScreen.width * ( props.small ? .15 : .2 ) }px;
    border-radius: 5px;
    overflow: hidden;  
    justify-content:center;
`;

export const Spacer = styled.div.attrs({})`
    flex:1; 
    min-height:40px;
`; 

export const RowBetween = styled.div.attrs({})`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position:relative;
`;











export const SmartphoneFrame = styled.div.attrs(props => ({
//   role: 'presentation', 
//   'data-theme': props.theme || 'light', 
  children: <>
      <Screen>
        {props.children}
      </Screen>
      <TopBar>
        <Camera />
      </TopBar>
  </>
}))`
//   width: 300px; 
//   height: 600px;
    width: 100%;
    max-width: 640px; 
    ${
        p => p.big ? `
            max-width: 1280px; 
            width: 100%;
        ` : ``  
    }
  border-radius: 40px; /* Rounded corners */
  background-color: #fff; /* White background */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Soft shadow for the phone frame */
  position: relative;
  overflow: hidden;
`;

const Screen = styled.div`
  width: 100%;
  height: 100%;
  background-color: #e0e0e0; /* Light gray background for the screen */
//   display: flex;
//   justify-content: center;
//   align-items: center;
`;

const TopBar = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 10px;
  background-color: #333;
  border-radius: 10px;
`;

const Camera = styled.div`
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background-color: #333;
  border-radius: 50%;
`;

 

export const MobileHide = styled.div`
  @media(max-width: 991px){
    display: none;
  }
`;

 