import React, { useEffect, useState } from "react";  

import {  
    ContentRight,
    ContentRightItem,
    ContentRightTitle,
    CardOption, 
    CardOptionResponse, 
    CardOptionTitle,  
    CardOptionDecoration,
    CardOptionDecorationIcon,
    CardFormBetweeRow,
    CardFormResponse,
    CardFormResponseFlex,
    CardFormResponseActions,
    IconRemove
} from "./styled";


import Input from "components/Form/Input";
import { FormAction, FormActions, FormContainer, GeneratedAction, MaxButton } from "ui/styled";
import Button from "components/Form/Button";
import CustomButton from "components/Form/CustomButton";

export default function DashboardCardMultiChoice({ toggleSelected, isSelected, item, updateSuggestion }){  

    const [open, setOpen] = useState(false)


    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const [options, setOptions] = useState([])

    const optionAdd = () => {
        setOptions([
            ...options,
            { id:`${ new Date().getTime() }`, answer:"", correct:false }
        ])
    }

    const optionRemove = (item) => {
        setOptions([ ...options.filter(f => f.id !== item?.id ) ])
    }

    const handleCorrect = key => {
        const payload = {}
        options?.forEach((f , k) => {
            payload[`correct${k}`] = false
        })
        payload[`correct${key}`] = true
        setForm({
            ...form,
            ...payload
        })
    }

    const save = () => {
        setOpen(false)
        updateSuggestion(form)
    }

    useEffect(() => {


        const payload = {}

        item?.options?.forEach((f,k) => {
            payload[`answer${k}`] = f?.answer
            payload[`correct${k}`] = !!f?.correct
        })

        const nextForm = {
            ...item,
            ...payload
        }

        setForm({ ...nextForm })
        setOptions(item?.options || [])
        console.log("Current ITEM", item)
    }, [item])

    return ( 
        <>
            <CardOption onClick={() => toggleSelected(item)}>
                {
                    !isSelected(item) ? null : 
                    <CardOptionDecoration>
                        <CardOptionDecorationIcon />
                    </CardOptionDecoration>
                }

                <CardOptionTitle>
                    { item?.question }
                </CardOptionTitle>

                <CardOptionResponse bold>
                    Opções de respostas
                </CardOptionResponse>
                {
                    item?.options?.map((it, ky) => 
                        <CardOptionResponse responsed correct={it?.correct} key={`${ky}`}>
                            { it?.answer }
                        </CardOptionResponse>
                    )
                }
                
                <CardOptionResponse bold>
                    Feedback: 
                </CardOptionResponse>
                <CardOptionResponse>
                    { item?.feedback }
                </CardOptionResponse>

                {
                    !open ? 
                        <GeneratedAction className="edit-button">
                            <Button outline primary onClick={() => setOpen(true)}>Editar</Button>
                        </GeneratedAction> 
                    : 
                        <FormContainer>
                            <Input placeholder="Pergunta" value={formValue("question")} onChange={e => changeForm(e.target.value, "question")} />
                            
                            {
                                options.map((item, key) =>
                                    <CardFormResponse key={key}>
                                        <CardFormResponseFlex>
                                            <Input placeholder="Resposta" value={formValue(`answer${key}`)} onChange={e => changeForm(e.target.value, `answer${key}`)} />
                                        </CardFormResponseFlex>
                                        <CardFormResponseActions>
                                                <MaxButton small>
                                                    <Button outline primary nospace onClick={() => optionRemove(item)} >
                                                        <IconRemove />
                                                    </Button>
                                                </MaxButton>
                                                <ContentRight> 
                                                    <ContentRightItem active={!!formValue(`correct${key}`)} onClick={() => handleCorrect(key)}>Verdadeiro</ContentRightItem>
                                                    <ContentRightItem active={!formValue(`correct${key}`)} onClick={(v) => changeForm(false, `correct${key}`)}>Falso</ContentRightItem>
                                                </ContentRight>
                                        </CardFormResponseActions>
                                    </CardFormResponse>
                                )
                            }

                            <FormAction big>
                                <CustomButton outline onClick={optionAdd}>Adicionar opção de resposta</CustomButton> 
                            </FormAction>

                            <Input type="textarea" placeholder="Feedback" value={formValue("feedback")} onChange={e => changeForm(e.target.value, "feedback")} />        
                            
                            <CardFormBetweeRow>
                                <ContentRight />  
                                <FormAction>
                                    <CustomButton onClick={save}>Salvar</CustomButton> 
                                </FormAction>
                            </CardFormBetweeRow>
                        </FormContainer>
                }

            </CardOption>              
        </>
    );
}